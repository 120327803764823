import React from "react"

import styles from "../../styles/main.module.css"

export default function HelloWorld() {
    const intro = {
        "heading": "Socrates: A Zen Master",
        "p1" : "Recently, I read Euthyphro, The Apology, and Crito (from Plato’s five dialogs) as a part of a reading group a friend of mine was hosting. Having never read philosophy of this kind, I couldn’t help but see Zen in Socrates’ arguments. It’s hard to imagine somehow an ancient Greek would share the same philosophy as some Chinese monks more than a thousand years later, but this is a line worth exploring because despite all their cultural differences, I think the core teaching is very similar.",
        "p2" : "Zen, known for being relentless in its nonsensical directness, at once “exasperating and delightful,” has long been elusive to the West. With the first waves of globalization, Buddhism and its many branches disseminated throughout the world. Now mindfulness, meditation, and Zen have become household names. However, it’s clear that in our consumerist society these traditions have become mostly show and very little substance. Just look at the size of the spirituality market—if the stuff they sold you worked, the market would be much smaller. It’s hard to not be disheartened then, worrying that there might not be anything to it at all in the Buddha’s teachings. My goal is to show you that there definitely is something, and that Socrates’ methods are a great angle to get at it.",
    };

    const zen = {
        "heading" : "Zen",
        "p1" : "Zen is a branch of Buddhism, practiced mainly in Japan and a little bit in China. It’s unique due to its heavy Taoist influence. Zen is to a large extent, ineffable, so we must describe what it is not instead. Supposedly, the whole point is to liberate yourself of suffering, though that is a bit of a narrow view. Zen achieves this by inducing one or more sudden enlightenments (satoris). Through satoris you attain a special kind of wisdom which helps liberate your mind and actions from suffering. The wisdom, however, does more than erase suffering—it also opens doors to other potential insight—which is why the suffering-only view is a bit narrow. Socrates focuses on the wisdom, so that will be our focus too.",
        "p2" : "The wisdom Socrates and Zen point to is an understanding of the world as it is—“thus” (gesticulating radially outwards from your point of view). Accordingly, this wisdom is often referred to as “thusness.” It’s easiest to see when looking at the horizon, especially towards the ocean on a cloudy sunset. You can point and say “I see that.” Are you pointing at the sky or the ocean? How can you tell what you were pointing to? Where is the separation between ocean and sky? Most of us have some idea of where it is, but cannot be sure. It’s hard to say it exists, since we can only perceive a gradient of light. Any notion of a round earth with water on it’s in our minds, not in this perception. Any notion of lines of sight is just an idea—do lines, in the platonic sense, exist in the physical world? In that vein, you might say that all the world is simply a mishmash of colors, which we interpret as containing boundaries. Boundaries are an abstraction—a convention. We can tell where a table starts and stops, but with only our visual perception of reality, all we can say is that there are only colors there; no matter how sharp the boundary, look long enough and it’s apparent. With our other senses we can also tell that there are feelings of touch or sound there too if we move our body in certain ways (to knock on the table, maybe), so all that can be said to be there is sense data. In fact, we cannot say that the very notion of “thereness” is anything more than an abstraction too, since if we simply perceive sense data, the existence of spatial dimensions is simply inferred as a useful convention to function in this world. Colors too, are abstractions.",
        "p3" : "So it would appear the entirety of the exterior world is perceived, but perhaps the mental world exists? This seems to be a strange assertion since if the exterior world is only perceived, then it would not make sense to place it outside of the mental world. Beyond usefulness, if we just want to know the world as it is, then why invent an “exterior” world? There is only one world, and it encompasses everything. The rejection of boundaries can also be applied to ideas. Take the self. Where does the self stop, and where does it begin? Any physical description is inadequate. Am I less myself for losing a finger, an arm, a hemisphere of my brain? There is no satisfactory answer. Philosophers have thought of this for years. The problem is that there is no self, there just is thusness, and so philosophers are not discovering the self, they are defining it. Our default understanding of the self is purely emergent from our conscious experiences in reality, and since our inner workings are not rational, categorical, and clearly defined, it’s inevitable that our default sense of self is imprecise. Of course, don’t forget that consciousness is thusness, just as the world is thusness, so the previous sentence is only a mental model, an abstraction, a convention—as are all sentences, as is logic, as are categories.",
        "p4" : "Note how, just as you can view mental processes as physical—of the brain—exterior, you can also see worldly processes as perceptions—of the mind—interior. There is no more realness in one than in the other. Everything is just as exterior as it is interior, so it’s both, and yet in a deeper sense of “realness” it must be neither—instead, thusness. The broader rejection of things being either one thing or another—true or false—existing or not existing—is referred to as non-duality. This includes rejection of the premise of categories. Ironically, the Zen master rejects the premise of Zen. At its core, any Zen traditions simply are. We all know to call Zen, Zen—to do otherwise would be absurd and confusing—but to ask what is Zen? We might just as well answer that it’s “three pounds of flax” as is the Buddha.",
        "p5" : "It’s crucial to see that this is not nihilism, contextualism, or claiming that “nothing is real” or “everything is unique.” Remember that Zen rejects the premise of the questions that yield such answers. When the Buddha was asked not for words, not for non-words, he sat silently. Understanding this aspect of Zen is a bit subtle and you’ll often miss the mark. You must feel it, not just understand it with words and reason. To understand it with words would be to not understand it at all. A good starting point involves mindfulness meditation—open eyes, concentrating on breath and visual-auditory perception—followed by the harnessing of the “oceanic feeling” as described by Freud—an all-encompassing feeling of oneness.",
    };

    const socrates = {
        "heading": "Socratic Zen",
        "p1": "The traditional experience of a Zen pupil (specifically of the Rinzai school) involves solving various puzzles, called Koans, in which your ability to solve approximates your level of advancement in understanding of Zen. These are fluid puzzles with no set solution, and instead, a master understands whether you have solved the puzzle or not. These Koans are similar to the socratic dialogues in their clever wordplay and logical twists.",
        "p2": "In one, a monk holds “up a shippei (staff of office) before his disciples and [says], ‘You monks! If you call this a shippei, you oppose its reality. If you do not call it a shippei, you ignore the fact. Tell me, you monks, what will you call it?’” To provide an answer through reason would lead to absurdity or inaccuracy. To call it “a staff made of wood,” you would not know what wood or a staff is. Keep asking and eventually we have a logical loop or a concept which cannot be explained in words. Imagine a child who keeps asking what things are made of, or why things are the way they are. We understand Shippei as we understand thusness, tacitly. Abstractions and conventions can help us point, but we must remember that things such as a Shippei are not their abstractions such as “Shippei.”",
        "p3": "When Socrates tries to get to the bottom of others’ wisdom, he points out this same truth through exhaustion. When he was younger he’d heard that the oracle of Delphi had announced that he was the wisest of all men. Knowing that he knew nothing, he viewed the god’s message as a riddle, and embarked on a mission to solve it. He questioned the politicians, poets, craftsmen, and other wise men of Athens to discover their knowledge. In every case he discovered that they often didn’t know what they claimed to know, and never once realized that they did not know. Thus, he decided that his wisdom must stem from the fact that he knew that he knew nothing.",
        "p4": "By questioning incessantly his conversation partners often end up tied into logical loops, trapped in absurd conclusions, or simply unable to explain their knowledge. This is inevitable. All knowledge in words stems from understanding of those words, which if we are to function successfully in this world, necessitates a mapping of those words to thusness. As thusness transcends logic (remember logic is of thusness, not thusness of logic) the corresponding words cannot be precisely reasoned with. Even if we abandon the world and delve into the realm of pure mathematics where everything is defined in terms of a small set of axioms, we still are eluded. All of mathematics is simply another facet of thusness. This is not because thusness is “everything” and thus mathematics is logically included. It’s because you can observe it being true. Simply, forget what the words “not,” “and,” “or,” “set,” and “in” mean, and mathematics doesn’t work. Notice how we cannot explain what these words mean, we simply know. These are thought patterns innate, to humans, and so are not teachable to someone who doesn’t have these thought patterns.",
        "p5": "Similarly, consider when Socrates explores virtue and related concepts. Long enough into a socratic dialog his partner’s purported wisdom starts to make no sense. In Euthyphro, Socrates finds Euryphro’s explanation of piety to be unsatisfying and shows that his arguments are contradictory. No matter what Euryphro tries he fails to avoid contradiction. As we’ve seen this is inevitable. Any explanation must eventually be ill-defined, fluid, made of thusness, or logically absurd. It appears that Socrates is using this to annoy people, though by forcing arguments to confront thusness his method serves as an effective pedagogical tool for listeners to understand reality as it is.",
        "p6": "By remembering that abstract notions are not “truths” we are kept honest. We can adapt to the fluidity of reality and perform better. It’s not that there is no right and no wrong for example. Instead, right and wrong simply cannot be explained—they must be learned. They are abstractions, but certainly useful ones at that, as they have helped us improve society greatly. In seeing the world as it is, we avoid the trap of valuing ideals over the world—and a trap this is, since ideals are not well defined and may thus drive us to unreasonable actions. Moreover, when abstractions of reality with reality are no longer useful we can change them or drop them. This applies not just to philosophical concepts but scientific ones. It is completely possible that by rethinking mathematics we may be able to make massive cognitive leaps. There is no reason to think that the logical sequential abstractions we use for mathematics are not limited in their ability to describe thusness. They certainly would appear to, given Gödel’s theorem of incompleteness. By remembering thusness and describing it differently, we may be able to form abstractions that advance physics greatly, and the only way to know is to try.",
    };

    return (
      <React.Fragment>
        <h2>{intro.heading}</h2>
        <hr className={styles.top_seperator} />
        <p>{intro.p1}</p>
        <p>{intro.p2}</p>
        <h2>{zen.heading}</h2>
        <p>{zen.p1}</p>
        <p>{zen.p2}</p>
        <p>{zen.p3}</p>
        <p>{zen.p4}</p>
        <p>{zen.p5}</p>
        <h2>{socrates.heading}</h2>
        <p>{socrates.p1}</p>
        <p>{socrates.p2}</p>
        <p>{socrates.p3}</p>
        <p>{socrates.p4}</p>
        <p>{socrates.p5}</p>
        <p>{socrates.p6}</p>
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}